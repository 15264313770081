var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"tw-flex tw-justify-between tw-w-full tw-mb-3"}),_c('ax-table',{attrs:{"headers":_vm.batchesHeader,"items":_vm.batches,"column-select":false,"options":_vm.tableState,"item-key":"id","footer-props":_vm.batchesFooter,"server-items-length":_vm.serverItemsLength},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"item.source",fn:function(ref){
var item = ref.item;
return [(item.status === _vm.BatchStatuses.AwaitingApproval)?_c('router-link',{attrs:{"to":{
          path: ("/manage/tasks/batches/" + (item.id)),
          query: { o: _vm.orgId },
        }}},[_vm._v(" "+_vm._s(item.source)+" ")]):_c('div',[_vm._v(_vm._s(item.source))])]}},{key:"item.created_by_user",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_by_user.email)+" ")]}},{key:"item.report_source",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.report_source)+" ")]}},{key:"item.impacted_device_count",fn:function(ref){
        var item = ref.item;
return [(
          item.status === _vm.BatchStatuses.AwaitingApproval ||
          item.status === _vm.BatchStatuses.Rejected ||
          item.status === _vm.BatchStatuses.Building
        )?_c('abbr',{attrs:{"title":"device count unavailable"}},[_vm._v("--")]):_c('span',[_vm._v(_vm._s(item.impacted_device_count))])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-items-center"},[(item.status === _vm.BatchStatuses.Building)?_c('ax-loader-bars'):_vm._e(),_vm._v(" "+_vm._s(_vm._f("snakeToReadable")(item.status))+" "),(item.status === _vm.BatchStatuses.Error)?_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({staticClass:"tw-ml-2 ax-text-teal",attrs:{"role":"button","tabindex":"0"}},on),[_c('v-icon',{attrs:{"role":"img","aria-hidden":"false","aria-label":"help text - batch status","dense":"","color":"teal"}},[_vm._v(" "+_vm._s(_vm.mdiInformationOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$ts('genericErrorMsg'))+" ")])]):_vm._e()],1)]}},{key:"top",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-between tw-items-end tw-w-full tw-mb-3"},[_c('ax-button',{attrs:{"mode":"primary","aria-label":"Add a new task","data-test-id":"add-task-btn-batches-table"},on:{"click":_vm.addTaskClicked}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.mdiPlusCircleOutline))]),_vm._v(" Add Task ")],1),_c('ax-timestamp',{attrs:{"time-stamp":_vm.loadTimestamp}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }